import { defaultTheme } from 'react-admin';
import type { RaThemeOptions } from 'react-admin';

export const darkTheme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    background: {
      default: '#424242',
    },
    primary: {
      contrastText: '#ffffff',
      main: '#52c9d4',
      light: '#9bf5fe',
      dark: '#21a1ae',
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: '#51b2bc',
    },
    mode: 'dark',
  },
  components: {
    ...defaultTheme.components,
    // @ts-ignore react-admin doesn't add its own components
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #52c9d4',
          },
        },
      },
    },
  },
};

export const lightTheme: RaThemeOptions = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      contrastText: '#ffffff',
      main: '#96b1ff',
      light: '#c7d6ff',
      dark: '#6085ff',
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: '#adadad',
    },
    mode: 'light',
  },
  components: {
    // @ts-ignore react-admin doesn't add its own components
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #38a9b4',
          },
        },
      },
    },
  },
};
