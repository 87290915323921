import {
  TextField,
  SearchInput,
} from "react-admin";
import RolesField from "../../fields/RolesField";
import {ListGuesser} from "@api-platform/admin";
export const UserList = (props : any) => {
  const filters = [
    <SearchInput source="name" alwaysOn resettable={true}/>,
  ];

  return (
    <ListGuesser {...props} filters={filters}>
      <TextField source="name" label={'Name'}/>
      <TextField source="email" label={'E-mail'}/>
      <RolesField source="roles" label={'Roles'}/>
    </ListGuesser>
  );
};

export default UserList;
