import {
  TextField,
  ReferenceField
} from "react-admin";

import {
  ListGuesser,
} from "@api-platform/admin";

export const GroupList = (props : any) => (
  <ListGuesser {...props}>
    <TextField source={"name"} />
    <ReferenceField
        source="owner"
        reference="users"
        label="Owner"
      >
      <TextField source="name" />
    </ReferenceField>
    {/*<ReferenceField*/}
    {/*    source="groupUsers"*/}
    {/*    reference="group_users"*/}
    {/*    label="group users"*/}
    {/*  >*/}
    {/*  <TextField source="id" />*/}
    {/*</ReferenceField>*/}
    <TextField source={"description"} />
    {/*<ReferenceField*/}
    {/*    source="groupInvites"*/}
    {/*    reference="group_invites"*/}
    {/*    label="invites"*/}
    {/*  >*/}
    {/*  <TextField source="id" />*/}
    {/*</ReferenceField>*/}
  </ListGuesser>
);

export default GroupList;
