import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';

const RolesField = (props : any) => {
  const record = useRecordContext(props);
  return <div>
    {record.roles.map((item : string) => (
      <div key={item}>{item}</div>
    ))}
  </div>;
}

RolesField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RolesField;
