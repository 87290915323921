import {
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
  List,
  Datagrid,
  ReferenceInput,
  SelectInput,
} from "react-admin";

export const GroupInviteList = (props : any) => {
  const filters = [
    <ReferenceInput source="group"
      reference="groups"
      label="Group"
      key={'group'}
      >
      <SelectInput optionText="name"/>
    </ReferenceInput>,
    <ReferenceInput source="user"
                    reference="users"
                    label="User"
                    key={'user'}
    >
      <SelectInput optionText="name"/>
    </ReferenceInput>
  ];

  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <ReferenceField
          source="group"
          reference="groups"
          label="Group"
        >
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField
          source="user"
          reference="users"
          label="User"
        >
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField
          source="createdBy"
          reference="users"
          label="Invited by"
        >
          <TextField source="name"/>
        </ReferenceField>
        <DateField source={"createdOn"} showTime={true} label={"Sent on"} />
        <BooleanField source={"pending"} />
        <BooleanField source={"accepted"} />
        <DateField source={"answered"} showTime={true} label={"Answered on"} />
      </Datagrid>
    </List>
  );
};

export default GroupInviteList;
