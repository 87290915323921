import {
  TextField,
  ReferenceField,
  DateField,
  List,
  Datagrid,
  ReferenceInput,
  SelectInput,
} from "react-admin";

export const GroupUserList = (props : any) => {
  const filters = [
    <ReferenceInput source="group"
      reference="groups"
      label="Group"
      >
      <SelectInput optionText="name"/>
    </ReferenceInput>,
    <ReferenceInput source="user"
                    reference="users"
                    label="User"
    >
      <SelectInput optionText="name"/>
    </ReferenceInput>
  ];

  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <ReferenceField
          source="group"
          reference="groups"
          label="group"
        >
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField
          source="user"
          reference="users"
          label="User"
        >
          <TextField source="name"/>
        </ReferenceField>
        <TextField source={"role"}/>
        <DateField source={"joined"} showTime={true}/>
      </Datagrid>
    </List>
  );
};

export default GroupUserList;
