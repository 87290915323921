import React, {SetStateAction, useState} from 'react';
import {Navigate, Route} from "react-router-dom";
import {
  CustomRoutes,
  Layout,
  LayoutProps,
  Login,
  LoginClasses,
  resolveBrowserLocale,
} from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import frenchMessages from "ra-language-french";
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  ResourceGuesser,
  useIntrospection,
} from "@api-platform/admin";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";

import AppBar from "./AppBar";
import frMessages from "../i18n/fr";
import enMessages from "../i18n/en";
import {LoginForm} from "./LoginForm";
import authProvider from "../utils/authProvider";
import UserList from "./resources/user/UserList";
import UserCreate from "./resources/user/UserCreate";
import GroupList from "./resources/group/GroupList";
import GroupInviteList from "./resources/group_invite/GroupInviteList";
import UserEdit from "./resources/user/UserEdit";
import GroupUserList from "./resources/group_user/GroupUserList";
const ENTRYPOINT = (process.env.REACT_APP_API_BASE as string);

const getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url: URL, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    // @ts-ignore
    headers: getHeaders,
  });
const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login"/>;
};
const apiDocumentationParser = (setRedirectToLogin: (arg0: boolean) => void) => async () => {
  try {
    setRedirectToLogin(false);

    // @ts-ignore
    return await parseHydraDocumentation(ENTRYPOINT, {headers: getHeaders});
  } catch (result) {
    // @ts-ignore
    const {api, response, status} = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem("token");

    setRedirectToLogin(true);

    return {
      api,
      response,
      status,
    };
  }
};
const dataProvider = (setRedirectToLogin: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) => baseHydraDataProvider({
  useEmbedded: false,
  // @ts-ignore
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
});

const messages = {
  fr: {...frenchMessages, ...frMessages},
  en: {...englishMessages, ...enMessages},
};

const i18nProvider = polyglotI18nProvider(
  // @ts-ignore
  (locale) => (messages[locale] ? messages[locale] : messages.en),
  resolveBrowserLocale(),
);

const LoginPage = () => (
  <Login
    sx={{
      backgroundImage:
        'radial-gradient(circle at 50% 14em, #90dfe7 0%, #288690 60%, #288690 100%)',
      [`& .${LoginClasses.icon}`]: {
        backgroundColor: 'secondary.main',
      },
    }}>
    <LoginForm/>
  </Login>
);

const MyLayout = (props: JSX.IntrinsicAttributes & LayoutProps) => <Layout {...props} appBar={AppBar} />;

const AdminUI = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  return <HydraAdmin
    dataProvider={dataProvider(setRedirectToLogin)}
    authProvider={authProvider}
    entrypoint={ENTRYPOINT}
    i18nProvider={i18nProvider}
    layout={MyLayout}
    loginPage={LoginPage}>
    {permissions => (<>
      <CustomRoutes>
        {redirectToLogin ? <Route path="/" element={<RedirectToLogin/>}/> : null}
      </CustomRoutes>
      <ResourceGuesser name="groups" list={GroupList}/>
      <ResourceGuesser name="group_invites" list={GroupInviteList}/>
      <ResourceGuesser name="group_users" list={GroupUserList}/>
      <ResourceGuesser name="authors"/>
      <ResourceGuesser name="books"/>
      <ResourceGuesser name="user_books"/>
      <ResourceGuesser name="requests"/>
      <ResourceGuesser name="users" list={UserList} create={UserCreate} edit={UserEdit} />
      <ResourceGuesser name="fcm_tokens"/>
    </>)}
  </HydraAdmin>;
};
// const App = () => <HydraAdmin entrypoint="https://localhost:8443" />;

const App = () => <AdminUI />;

export default App;
