import {
  TextInput,
  SelectArrayInput
} from "react-admin";

import {
  CreateGuesser,
} from '@api-platform/admin';


export const UserCreate = (props : any) => (
  <CreateGuesser {...props}>
    <TextInput source={'name'} />
    <TextInput source={'email'} type="email" />
    <TextInput source={'plainPassword'} type="password" label={'Mot de passe'} />
    <SelectArrayInput source={'roles'} choices={[
      { id: 'ROLE_USER', name: 'Utilisateur' },
      { id: 'ROLE_ADMIN', name: 'Administrateur' },
    ]}>
    </SelectArrayInput>
  </CreateGuesser>
);

export default UserCreate;
