import {
  TextInput,
  SelectArrayInput
} from "react-admin";

import {
  EditGuesser,
} from '@api-platform/admin';

export const UserEdit = (props : any) => (
  <EditGuesser {...props}>
    <TextInput source={'name'} />
    <TextInput source={'email'} type="email" />
    <TextInput source={'plainPassword'} type="password" label={'Mot de passe'} />
    <SelectArrayInput source={'roles'} choices={[
      { id: 'ROLE_USER', name: 'Utilisateur' },
      { id: 'ROLE_ADMIN', name: 'Administrateur' },
    ]}>
    </SelectArrayInput>
  </EditGuesser>
);

export default UserEdit;
